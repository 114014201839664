<template>
  <el-dialog top="5vh" modal-append-to-body :close-on-click-modal="false" :before-close="close" title="入仓申请表" width="65%" :visible.sync="show">
    <el-form :model="ruleForm" :rules="rules" size="small" ref="ruleForm" label-position="left" label-width="100px">
      <div class="multiple-box" v-if="show">
        <div class="sku-item" v-for="(item, i) in CODApplyListData" :key="item.Id">
          <i @click="removeItem(item, i)" v-if="dialogStatus==='update'" class="absolute-icon el-icon-close"></i>
          <div class="item">
            <div class="label">SKU编码</div>
            <div class="value">{{ item.SKUCode }}</div>
          </div>
          <div class="item">
            <div class="label">名称</div>
            <div class="value">{{ item.Name }}</div>
          </div>
<!--          <div class="item">-->
<!--            <div class="label">商品品类</div>-->
<!--            <div class="value">{{ item.ProductName }}</div>-->
<!--          </div>-->
          <el-form-item label="预计数量" :prop="item.SKUCode">
            <el-input v-model="ruleForm.CODApplyInfos[i].Quantity" type="number"></el-input>
          </el-form-item>
          <div style="width: 100%; text-align: center" v-if="!expands[item.SKUCode]">
            <el-button @click="handleChangeExpand(item.SKUCode)" size="small" type="text">
              展开详细信息
              <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
          </div>
          <div v-if="expands[item.SKUCode]">
            <div class="item">
              <div class="label">尺寸</div>
              <div class="value">{{ `${item.Length}*${item.Width}*${item.Height}` }}</div>
            </div>
            <div class="item">
              <div class="label">尺码</div>
              <div class="value">{{ item.Size }}</div>
            </div>
            <div class="item">
              <div class="label">规格</div>
              <div class="value">{{ item.ModelNumber }}</div>
            </div>
            <div class="item">
              <div class="label">重量</div>
              <div class="value">{{ item.Weight }}</div>
            </div>
            <div class="item" v-if="item.ImagePath">
              <div class="label">图片</div>
              <el-image class="sku-img" :preview-src-list="[item.ImagePath]" :src="item.ImagePath"></el-image>
            </div>
            <div class="item">
              <div class="label rem">备注</div>
              <div class="rem">{{ item.Rem }}</div>
            </div>
          </div>
          <div style="width: 100%; text-align: center" v-if="expands[item.SKUCode]">
            <el-button @click="handleChangeExpand(item.SKUCode)" size="small" type="text">
              收起详细信息
              <i class="el-icon-arrow-up el-icon--right"></i>
            </el-button>
          </div>
        </div>
        <div v-if="dialogStatus==='update'" @click="showDrawer" class="sku-item add-item">
          <i class="el-icon-plus add-item-icon"></i>
        </div>
      </div>
      <el-form-item label="入仓申请号" prop="OrderCode">
        <el-input v-model="ruleForm.OrderCode" type="text" style="width: 36rem" placeholder="请填写入仓申请号"></el-input>
      </el-form-item>
      <el-form-item label="所属仓库" prop="WareHouseId">
        <el-select v-model="ruleForm.WareHouseId" placeholder="请选择仓库" style="width: 36rem">
          <el-option v-for="item in warehouseOptions" :key="item.Id" :label="item.Name" :value="item.Id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="备注说明" prop="Rem">
        <el-input v-model="ruleForm.Rem" type="textarea" style="width: 36rem" placeholder="如有其他需求或有特别需要注意的,请留下您的需求备注信息"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer" style="text-align: left">
      <el-button v-loading="loading" type="primary" @click="submitForm('ruleForm')">提交</el-button>
      <el-button @click="close">取消</el-button>
    </div>
    <s-k-u-drawer @close="handleDrawerClose" @handleCheck="handleChangeSKU" :s-k-u-ids="SKUIds" :drawer="drawer"></s-k-u-drawer>
  </el-dialog>
</template>

<script>

import {addCodApply, updateCodSKUApply} from "@/api/cod";
import { mapGetters } from "vuex"
import SKUDrawer from "@/views/member/cod/SKUDrawer.vue";
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    CODApplyInfos: {
      type: Array,
      default: () => []
    },
    dialogStatus: {
      type: String,
      default: "create"
    },
    temp: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      ruleForm: {},
      rules: {
        OrderCode: [
          { required: true, message: '请输入入仓申请号', trigger: 'blur' }
        ],
        WareHouseId: [
          { required: true, message: '请选择仓库', trigger: 'change' },
        ],
      },
      warehouseOptions: [],
      loading: false,
      expands: {},
      drawer: false,
      checkedSKUList: [],
      deepCODApplyInfos: []
    }
  },
  watch: {
  },
  computed: {
    ...mapGetters(["userInfo"]),
    SKUIds() {
      return this.CODApplyListData.map(item => {
        return item.Id
      })
    },
    CODApplyListData() {
      return [...this.deepCODApplyInfos, ...this.checkedSKUList]
    }
  },
  components: {
    SKUDrawer
  },
  methods: {
    showDrawer() {
      this.drawer = true
    },
    handleChangeSKU(checked) {
      let applyArray = []
      checked.forEach(item => {
        applyArray.push({
          SKUId: item.Id,
          Quantity: 0
        })
        this.rules[item.SKUCode] = [
          {validator: this.checkRange, trigger: ['blur']}
        ];
        this.$set(this.expands, item.SKUCode, false)
      })
      this.$set(this.ruleForm, 'CODApplyInfos', [...this.ruleForm.CODApplyInfos, ...applyArray]);
      this.checkedSKUList = checked
      this.drawer = false
    },
    handleDrawerClose() {
      this.drawer = false
    },
    checkRange(rule, value, callback) {
      const idx = this.deepCODApplyInfos.findIndex(item => item.SKUCode === rule.field);
      const realValue = this.ruleForm.CODApplyInfos[idx].Quantity
      if (!realValue) {
        callback(new Error('请输入入库数量'))
      }
      callback();
    },
    close() {
      this.$refs.ruleForm.clearValidate()
      this.$emit('close')
    },
    removeItem(row, i) {
      this.$confirm(`此操作将永久移除SKU编码为（${row.SKUCode}）的数据, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        if (this.dialogStatus === 'create') {
          this.deepCODApplyInfos.splice(i, 1)
        } else {
          if (this.validateFind(row.Id)) {
            const index = this.deepCODApplyInfos.findIndex(item => {
              return item.Id === row.Id
            })
            this.deepCODApplyInfos.splice(index, 1)
          } else {
            const index = this.checkedSKUList.findIndex(item => {
              return item.Id === row.Id
            })
            this.checkedSKUList.splice(index, 1)
          }
        }
        this.ruleForm.CODApplyInfos.splice(i, 1)
      })
    },
    validateFind(rowId) {
      if (!this.deepCODApplyInfos.length) {
        return false
      }
      return this.deepCODApplyInfos.map(item => item.Id).includes(rowId);
    },
    init() {
      this.getWareHouse()
      if (this.dialogStatus === 'create') {
        let applyArray = []
        this.CODApplyInfos.forEach(item => {
          applyArray.push({
            SKUId: item.Id,
            Quantity: 0
          })
          this.rules[item.SKUCode] = [
            {validator: this.checkRange, trigger: ['blur']}
          ];
          this.$set(this.expands, item.SKUCode, false)
        })
        this.$set(this.ruleForm, 'CODApplyInfos', applyArray);
      } else {
        this.checkedSKUList = []
        this.ruleForm = JSON.parse(JSON.stringify(this.temp))
        this.CODApplyInfos.forEach(item => {
          this.rules[item.SKUCode] = [
            {validator: this.checkRange, trigger: ['blur']}
          ];
          this.$set(this.expands, item.SKUCode, false)
        })
      }
      this.deepCODApplyInfos = JSON.parse(JSON.stringify(this.CODApplyInfos))
      this.$forceUpdate()
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.dialogStatus === 'create') {
            addCodApply({
              ...this.ruleForm,
              ClientId: this.userInfo.Id
            }).then(response => {
              const { Code, Msg, Success } = response
              if (Code === 200 && Success) {
                this.$message.success("下单成功！！")
                this.close()
              } else {
                this.$message.warning(Msg)
              }
            })
          } else {
            updateCodSKUApply({
              ...this.ruleForm,
              ClientId: this.userInfo.Id
            }).then(response => {
              const { Code, Msg, Success } = response
              if (Code === 200 && Success) {
                this.$message.success("修改成功！！")
                this.close()
              } else {
                this.$message.warning(Msg)
              }
            })
          }
        } else {
          return false;
        }
      });
    },
    handleChangeExpand(code) {
      this.$set(this.expands, code, !this.expands[code])
      this.$forceUpdate()
    },
    getWareHouse() {
      this.$store.dispatch('webSite/getWareHouse').then(data => {
        this.warehouseOptions = data
      })
    }
  }
}

</script>

<style scoped lang="scss">
.queryItems {
  display: flex;
  flex-wrap: wrap;
  //justify-content: space-between;
  align-items: center;
}
.multiple-box {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1rem;
  margin-bottom: 2rem;
  .add-item :hover {
    border-color: #409EFF !important;
  }
  .add-item {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    min-height: 20rem;
    .add-item-icon {
      font-size: 36px;
    }
  }
  .sku-item {
    .absolute-icon {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
    }
    padding: 12px;
    border: 1px solid #C0C4CC;
    border-radius: 8px;
    position: relative;
    .item {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      .sku-img {
        width: 100px;
        height: 100px;
        border-radius: 5px;
      }
      .label {
        width: 100px;
        color: #B7B9C1;
      }
      .rem {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        white-space: pre-wrap; /* 保留空白 */
        text-overflow: ellipsis;
        height: 2.4em; /* 根据行高调整这个值 */
      }
    }
  }
}
</style>