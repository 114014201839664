<template>
  <el-dialog :close-on-click-modal="false" :append-to-body="body" :before-close="close" title="入仓单明细" width="65%" :visible.sync="show">
    <div class="main-box">
      <div class="item">
        <div class="label">入仓单号:</div>
        <div class="value">{{ CodObject.OrderCode }}</div>
      </div>
      <div class="item">
        <div class="label">仓库:</div>
        <div class="value">{{ CodObject.WareHouseName }}</div>
      </div>
      <div class="item">
        <div class="label">申请数量:</div>
        <div class="value">{{ CodObject.Quantity }}</div>
      </div>
      <div class="item">
        <div class="label">实到数量:</div>
        <div class="value">{{ CodObject.StorageQuantity }}</div>
      </div>
      <div class="item">
        <div class="label">创建日期:</div>
        <div class="value">{{ CodObject.CreateTime | formatDate }}</div>
      </div>
      <div class="item">
        <div class="label">备注:</div>
        <div class="value">{{ CodObject.Rem }}</div>
      </div>
    </div>
    <el-table
        :data="listData"
        border
        size="mini"
        style="width: 100%"
    >
<!--      <el-table-column label="日期" prop="CreateTime" align="center" width="150px">-->
<!--        <template slot-scope="scope">-->
<!--          <span>{{ scope.row.CreateTime | formatDate }}</span>-->
<!--        </template>-->
<!--      </el-table-column>-->
      <el-table-column label="SKU编码" prop="SKUCode" align="center"></el-table-column>
      <el-table-column label="名称" prop="Name" align="center"></el-table-column>
      <el-table-column label="规格" prop="ModelNumber" align="center"></el-table-column>
      <el-table-column label="型号" prop="Specification" align="center"></el-table-column>
      <el-table-column label="尺码" prop="Size" align="center"></el-table-column>
      <el-table-column label="申请数量" prop="ApplyQuantity" align="center"></el-table-column>
      <el-table-column label="入仓数量" prop="StorageQuantity" align="center"></el-table-column>
    </el-table>
  </el-dialog>
</template>

<script>

export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    body: {
      type: Boolean,
      default: false
    },
    CodObject: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
    }
  },
  computed: {
    listData() {
      return this.CodObject.CODApplyInfos || []
    }
  },
  methods: {
    close() {
      this.$emit('close')
    }
  }
}

</script>

<style scoped lang="scss">
.main-box {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  margin-bottom: 2rem;
  .item {
    display: flex;
    align-items: center;
    font-size: 12px;
    .label {
      width: 72px;
      color: #a4a4a4;
    }
  }
}
</style>